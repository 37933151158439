import request from '@/utils/request' // 引入封装得axios

// 活动报名
export function activityReg (data) {
    return request({
        url: '/activity/activityReg',
        method: 'post',
        data
    })
}

// 获取活动中心分类
export function categories(data) {
    return request({
        url: '/activity/categories',
        method: 'get',
        params: data
    })
}

// 获取活动中心列表
export function listPage(data) {
    return request({
        url: '/activity/listPage',
        method: 'get',
        params: data
    })
}

// 获取活动详情
export function detail(data) {
    return request({
        url: '/activity/detail',
        method: 'get',
        params: data
    })
}

// 活动评价
export function comment (data) {
    return request({
        url: '/activity/comment',
        method: 'post',
        data
    })
}

// 获取活动详情
export function getComments(data) {
    return request({
        url: '/activity/getComments',
        method: 'get',
        params: data
    })
}

// 相关推荐
export function recommend(data) {
    return request({
        url: '/activity/recommend',
        method: 'get',
        params: data
    })
}
//获取 活动点赞和收藏状态
export function getStatus(data) {
    return request({
        url: '/activity/getStatus',
        method: 'get',
        params: data
    })
}
// 收藏取消收藏
export function favorite (data) {
    return request({
        url: '/activity/favorite',
        method: 'get',
        params:data
    })
}
// 点赞取消赞
export function like (data) {
    return request({
        url: '/activity/like',
        method: 'get',
        params:data
    })
}


