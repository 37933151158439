import request from '@/utils/request' // 引入封装得axios

// 获取新闻和通知
export function getNewsAndNotices (data) {
    return request({
        url: '/homePage/getNews',
        method: 'get',
        data
    })
}
export function getLines (data) {
    return request({
        url: '/homePage/getLines',
        method: 'get',
        data
    })
}
export function getCourses (data) {
    return request({
        url: '/homePage/getCourses',
        method: 'get',
        params:data
    })
}
export function getLives (data) {
    return request({
        url: '/homePage/getLives',
        method: 'get',
        data
    })
}

export function getBases (data) {
    return request({
        url: '/homePage/getBases',
        method: 'get',
        params:data
    })
}

export function getTravels (data) {
    return request({
        url: '/homePage/getTravels',
        method: 'get',
        params:data
    })
}
export function getExistingBaseArea (data) {
    return request({
        url: '/homePage/getExistingBaseArea',
        method: 'get',
        data
    })
}



