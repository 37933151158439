import request from '@/utils/request' // 引入封装得axios

// 获取新闻和通知
export function getList(data) {
    return request({
        url: '/carousel/getList',
        method: 'get',
        params: data
    })
}


