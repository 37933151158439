import { createStore } from 'vuex'

export default createStore({
  state: {
    //菜单选中项
    activeIndex:sessionStorage.getItem("menuActiveIndex"),
    //接口主路径
    baseUrl:process.env.VUE_APP_API_BASE_URL,
    //验证码令牌
    certCode:'',
    //当前登录的用户信息
    currentUser:{
      avatarImgPath:''
    },
    uploadImagePath:'/travel/upload/image',
    uploadVideoPath:'/travel/upload/video',
    editorBaseUrl:process.env.VUE_APP_EDITOR_BASE_URL,
    searchCondition:''
  },
  mutations: {
    //定义更新搜索条件
    search(state,condition){
      state.searchCondition = condition
    }
  },
  actions: {
  },
  modules: {
  }
})
