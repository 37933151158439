import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "banner-main"
};
const _hoisted_2 = ["src"];
import { reactive } from "vue";
import { getList } from "../../api/carousel"; // eslint-disable-next-line no-undef

export default {
  __name: 'Banner',
  props: {
    bannerConfig: Object
  },

  setup(__props) {
    const props = __props;
    const carousel = reactive({
      list: [{
        carouselPath: "image/12.png"
      }],
      show: false
    });
    getList({
      type: props.bannerConfig.type
    }).then(response => {
      carousel.list = response.data;
      carousel.show = true;
    });
    return (_ctx, _cache) => {
      const _component_el_carousel_item = _resolveComponent("el-carousel-item");

      const _component_el_carousel = _resolveComponent("el-carousel");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_Transition, {
        "enter-active-class": "animate__animated animate__fadeIn",
        "leave-active-class": "animate__animated animate__fadeOut"
      }, {
        default: _withCtx(() => [carousel.show ? (_openBlock(), _createBlock(_component_el_carousel, {
          key: 0,
          interval: 4000,
          height: __props.bannerConfig.height,
          "initial-index": 0
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(carousel.list.length, item => {
            return _openBlock(), _createBlock(_component_el_carousel_item, {
              key: item
            }, {
              default: _withCtx(() => [_createElementVNode("img", {
                src: _ctx.$store.state.baseUrl + carousel.list[item - 1].carouselPath
              }, null, 8, _hoisted_2)]),
              _: 2
            }, 1024);
          }), 128))]),
          _: 1
        }, 8, ["height"])) : _createCommentVNode("", true)]),
        _: 1
      })]);
    };
  }

};