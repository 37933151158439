import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {
  __name: 'App',

  setup(__props) {
    let locale = zhCn;
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      const _component_el_backtop = _resolveComponent("el-backtop");

      return _openBlock(), _createBlock(_unref(ElConfigProvider), {
        locale: _unref(locale)
      }, {
        default: _withCtx(() => [_createVNode(_component_router_view), _createVNode(_component_el_backtop)]),
        _: 1
      }, 8, ["locale"]);
    };
  }

};