import request from '@/utils/request' // 引入封装得axios

// 获取验证码
export function getVerificationCode (data) {
    return request({
        url: '/account/getVerificationCode',
        method: 'get',
        data
    })
}

// 用户注册
export function signUp (data) {
    return request({
        url: '/account/signUp',
        method: 'post',
        data
    })
}

// 用户登录
export function signIn (data) {
    return request({
        url: '/account/signIn',
        method: 'post',
        data
    })
}

// 获取登录状态
export function checkLogin (data) {
    return request({
        url: '/account/checkLogin',
        method: 'get',
        data
    })
}

// 退出登录
export function signOut (data) {
    return request({
        url: '/account/signOut',
        method: 'get',
        data
    })
}

// 修改密码
export function changePassword (data) {
    return request({
        url: '/account/changePassword',
        method: 'post',
        data
    })
}

// 获取验证码
export function getEmailOrPhoneVerificationCode (data) {
    return request({
        url: '/account/getEmailOrPhoneVerificationCode',
        method: 'get',
        params:data
    })
}
// 获取验证码
export function resetPassword (data) {
    return request({
        url: '/account/resetPassword',
        method: 'post',
        data
    })
}


