import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/common.css'
import 'animate.css'
import 'normalize.css'
import 'element-plus/dist/index.css'
import '@wangeditor/editor/dist/css/style.css'
//跳回页面顶部
router.afterEach(() => {
    window.scrollTo(0, 0);
})
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'
const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.mount('#app')
