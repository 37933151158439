import request from '@/utils/request' // 引入封装得axios

// 获取新闻和通知
export function getLevelO (data) {
    return request({
        url: '/area/getLevelO',
        method: 'get',
        data
    })
}


