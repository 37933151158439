import request from '@/utils/request' // 引入封装得axios

// 获取用户基本信息
export function getBasicInformation(data) {
    return request({
        url: '/userCenter/getBasicInformation',
        method: 'get',
        params: data
    })
}

// 编辑基本信息
export function modifyBasicInfo(data) {
    return request({
        url: '/userCenter/modifyBasicInfo',
        method: 'post',
        data
    })
}
// 获取我的游记
export function getMyTravel(data) {
    return request({
        url: '/userCenter/getMyTravel',
        method: 'get',
        params: data
    })
}
// 获取我的游记详情
export function getTravelDetail(data) {
    return request({
        url: '/userCenter/getTravelDetail',
        method: 'get',
        params: data
    })
}

// 获取个人收藏的游记
export function getTravelCollection(data) {
    return request({
        url: '/userCenter/getTravelCollection',
        method: 'get',
        params: data
    })
}
// 获取个人收藏的活动
export function getActivityCollection(data) {
    return request({
        url: '/userCenter/getActivityCollection',
        method: 'get',
        params: data
    })
}
// 获取个人收藏的基地
export function getBaseCollection(data) {
    return request({
        url: '/userCenter/getBaseCollection',
        method: 'get',
        params: data
    })
}
// 获取个人收藏的视频
export function getCourseCollection(data) {
    return request({
        url: '/userCenter/getCourseCollection',
        method: 'get',
        params: data
    })
}
// 获取个人收藏的路线
export function getLineCollection(data) {
    return request({
        url: '/userCenter/getLineCollection',
        method: 'get',
        params: data
    })
}



