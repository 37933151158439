import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  /*注册页面*/
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  /*登录页面*/
  {
    path: '/signin',
    name: 'Signin',
    component: () => import('../views/Signin.vue')
  },
  /*忘记密码页面*/
  {
    path: '/forgetPassword',
    name: 'ForgetPassword',
    component: () => import('../views/ForgetPassword.vue')
  },
  /*新闻资讯页面*/
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/News.vue')
  },
  /*新闻资讯详情页面*/
  {
    path: '/newsDetails',
    name: 'NewsDetails',
    component: () => import('../views/NewsDetails.vue')
  },
  /*帮助中心页面*/
  {
    path: '/helpCenter',
    name: 'HelpCenter',
    component: () => import('../views/HelpCenter.vue')
  },
  /*帮助中心详情页面*/
  {
    path: '/helpDetails',
    name: 'HelpDetails',
    component: () => import('../views/HelpDetails.vue')
  },
  /*活动中心页面*/
  {
    path: '/activityCenter',
    name: 'ActivityCenter',
    component: () => import('../views/ActivityCenter.vue')
  },
  /*活动中心详情页面*/
  {
    path: '/activityDetails',
    name: 'ActivityDetails',
    component: () => import('../views/ActivityDetails.vue')
  },
  /*游学基地*/
  {
    path: '/studyBase',
    name: 'StudyBase',
    component: () => import('../views/StudyBase.vue')
  },
  /*游学课程详情*/
  {
    path: '/curriculumDetails',
    name: 'CurriculumDetails',
    component: () => import('../views/CurriculumDetails.vue')
  },
  /*热门路线*/
  {
    path: '/popularRoute',
    name: 'PopularRoute',
    component: () => import('../views/PopularRoute.vue')
  },
  /*基地列表*/
  {
    path: '/popularBase',
    name: 'PopularBase',
    component: () => import('../views/PopularBase.vue')
  },
  /*路线详情*/
  {
    path: '/routeDetails',
    name: 'RouteDetails',
    component: () => import('../views/RouteDetails.vue')
  },
  /*热门课程列表*/
  {
    path: '/popularCourses',
    name: 'PopularCourses',
    component: () => import('../views/PopularCourses.vue')
  },
  /*研学游记*/
  {
    path: '/studyTravel',
    name: 'StudyTravel',
    component: () => import('../views/StudyTravel.vue')
  },
  /*研学游记详情*/
  {
    path: '/studyTravelDetails',
    name: 'StudyTravelDetails',
    component: () => import('../views/StudyTravelDetails.vue')
  },
  /*发布游记*/
  {
    path: '/publishTravel',
    name: 'PublishTravel',
    component: () => import('../views/PublishTravel.vue')
  },
  /*个人中心*/
  {
    path: '/personalCenter',
    name: 'PersonalCenter',
    component: () => import('../views/PersonalCenter.vue')
  },
  /*直播列表*/
  {
    path: '/liveBroadcast',
    name: 'LiveBroadcast',
    component: () => import('../views/LiveBroadcast.vue')
  },
  /*直播详情*/
  {
    path: '/liveBroadcastDetails',
    name: 'LiveBroadcastDetails',
    component: () => import('../views/LiveBroadcastDetails.vue')
  },
  /*入驻合作*/
  {
    path: '/settledCooperation',
    name: 'SettledCooperation',
    component: () => import('../views/SettledCooperation.vue')
  },
  /*平台介绍*/
  {
    path: '/platformIntroduction',
    name: 'PlatformIntroduction',
    component: () => import('../views/PlatformIntroduction.vue')
  },
  /*搜索页面*/
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search.vue')
  },
  /*编辑游记页面*/
  {
    path: '/editTravel',
    name: 'EditTravel',
    component: () => import('../views/EditTravel')
  },
  /*查看游记页面*/
  {
    path: '/viewTravel',
    name: 'ViewTravel',
    component: () => import('../views/ViewTravel')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
