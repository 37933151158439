import request from '@/utils/request' // 引入封装得axios


// 搜索直播带学
export function live(data) {
    return request({
        url: '/search/live',
        method: 'get',
        params: data
    })
}

// 搜索活动
export function activity(data) {
    return request({
        url: '/search/activity',
        method: 'get',
        params: data
    })
}

// 搜索游记
export function travel(data) {
    return request({
        url: '/search/travel',
        method: 'get',
        params: data
    })
}

// 搜索线路
export function line(data) {
    return request({
        url: '/search/line',
        method: 'get',
        params: data
    })
}

// 搜索视频
export function video(data) {
    return request({
        url: '/search/video',
        method: 'get',
        params: data
    })
}
// 搜索资讯
export function news(data) {
    return request({
        url: '/search/news',
        method: 'get',
        params: data
    })
}

// 获取搜索关键词
export function keyword(data) {
    return request({
        url: '/search/keyword',
        method: 'get',
        params: data
    })
}







