import Axios from 'axios'
import { ElMessageBox, ElMessage,ElLoading } from 'element-plus'
import router from '../router'
const axios = Axios.create({
    headers: {
        'Content-Type': 'application/json',
        'ste-token': localStorage.tokenValue
    },
    timeout: 60000, // 超时
    baseURL: process.env.VUE_APP_API_BASE_URL // 请求接口地址，这里使用本项目地址，因为我们是前后端分离，后面需要在vue.config.js里面配置代理，实际请求得地址不是这个。
})


//开始加载动画
let loading;
function startLoading() {
    loading  = ElLoading.service({
        text:'拼命加载中...',//加载中需要显示的文字
        background:'rgba(0,0,0,.5)',//背景颜色
    });
}
//结束加载动画
function endLoading() {
    loading.close();
}


axios.interceptors.request.use(req => {
    startLoading();//请求时的加载动画
    req.headers = {
        'Content-Type': 'application/json',
        'ste-token': localStorage.tokenValue
    }
    if (req.method === 'get') {
        const url = req.url
        const t = new Date().getTime()
        if (url.indexOf('?') >= 0) {
            req.url = `${url}&t=${t}`
        } else {
            req.url = `${url}?t=${t}`
        }
    }

    return req
})

// 响应拦截
axios.interceptors.response.use(
    response => {
        if (response) {
            endLoading()
            if (response.data.code !== 200) {
                if (response.data.code === 501) {
                    ElMessage({
                        message: response.data.msg,
                        type: "warning",
                    });
                    return new Promise(() => { });
                }
                if (response.data.code === 500) {
                    ElMessage({
                        message: response.data.msg,
                        type: "error",
                    });
                    return new Promise(() => { });
                }
                if (response.data.code === 401) {
                    ElMessageBox.confirm(
                        '您还没有登录，是否登录？',
                        '提示',
                        {
                            confirmButtonText: '是',
                            cancelButtonText: '否',
                            type: 'warning',
                        }
                    ).then(() => {
                        router.push("/signIn")
                    }).catch(() => {
                        ElMessage({
                            type: 'info',
                            message: '您取消了登录',
                        })
                    })
                    return new Promise(() => { });
                }
                return new Promise(() => { });
            }
            return response.data
        }

    },
    error => {
        // 响应失败统一处理
        const { response } = error
        if (response) {
            switch (response.code) {
                case 400:
                    ElMessageBox.alert(response.msg, '错误', {
                        confirmButtonText: 'OK',
                    })
                    break
                case 401:
                    ElMessageBox.alert(response.msg, '错误', {
                        confirmButtonText: 'OK',
                    })
                    break
                case 403:
                    ElMessageBox.alert(response.msg, '错误', {
                        confirmButtonText: 'OK',
                    })
                    break
                case 404:
                    ElMessageBox.alert(response.msg, '错误', {
                        confirmButtonText: 'OK',
                    })
                    break
                case 500:
                    ElMessageBox.alert(response.msg, '错误', {
                        confirmButtonText: 'OK',
                    })
                    break
                case 504:
                    ElMessageBox.alert(response.msg, '错误', {
                        confirmButtonText: 'OK',
                    })
                    break
                default:
                    ElMessageBox.alert(response.msg, '错误', {
                        confirmButtonText: 'OK',
                    })
                    break
            }
        }
        return Promise.reject(error)
    }
)
export default axios